import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ButtonMain from './ButtonMain'

import { changeType, editShift } from '../features/orderSlice'
import { useNavigate } from 'react-router-dom'
import { useDeleteOrderMutation } from '../features/apiSlice'

const EditMeal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const id = useSelector((state) => state.order.orderId)
  const { shifts, locations } = useSelector((state) => state.userOrderDetails)

  const [deleteOrder] = useDeleteOrderMutation()

  const handleDeleteOrder = () => {
    deleteOrder(id)
    dispatch(changeType(''))
  }

  return (
    <>
      <div
        onClick={() => dispatch(changeType(''))}
        className="modal-overlay"
      ></div>
      <div className="meal-popup">
        <p>Izaberite šta želite izmjeniti:</p>
        <ButtonMain
          background="blue"
          text="Izmjeni obrok"
          onClick={() => navigate('/order/edit')}
        />
        {shifts.length === 1 && locations.length === 1 ? null : (
          <ButtonMain
            onClick={() => {
              dispatch(editShift())
            }}
            background="grey"
            text="Izmjeni detalje"
          />
        )}
        <span></span>
        <ButtonMain
          onClick={() => handleDeleteOrder()}
          background="red"
          text="Obriši narudžbu"
        />
        <ButtonMain
          onClick={() => dispatch(changeType(''))}
          background="giveup"
          text="Odustani"
        />
      </div>
    </>
  )
}

export default EditMeal
