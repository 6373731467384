import auth from './features/authSlice'
import layout from './features/layoutSlice'
import order from './features/orderSlice'
import userOrderDetails from './features/userOrderDetails'

import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './features/apiSlice'

const store = configureStore({
  reducer: {
    auth,
    layout,
    order,
    userOrderDetails,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware)
  },
  devTools: true,
})

export default store
