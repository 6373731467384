import { useDispatch, useSelector } from 'react-redux'
import { modalToggler, signOutToggler } from '../features/layoutSlice'

import user from '../assets/images/user.svg'

const CloseModal = () => {
  const dispatch = useDispatch()
  const { name, username } = useSelector((state) => state.auth.session)

  const handleClick = (e) => {
    if (e.target instanceof HTMLButtonElement) return
    dispatch(modalToggler(false))
  }

  return (
    <div className="overlay" onClick={handleClick}>
      <div className="modal modal-close">
        <div className="user-info">
          <img src={user} alt="user" />
          <p>{name || username}</p>
        </div>
        <button
          className="close-btn"
          onClick={() => dispatch(signOutToggler(true))}
        >
          Odjavi se
        </button>
      </div>
    </div>
  )
}

export default CloseModal
