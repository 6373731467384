import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  closeModal: false,
  signOutModal: false,
}
export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    reset: () => initialState,
    modalToggler: (state, action) => {
      state.closeModal = action.payload
    },
    signOutToggler: (state, action) => {
      if (action.payload) {
        state.closeModal = false
        state.signOutModal = true
      } else {
        state.closeModal = false
        state.signOutModal = false
      }
    },
  },
})

export const { reset, modalToggler, signOutToggler } = layoutSlice.actions
export default layoutSlice.reducer
