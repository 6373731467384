import { React } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const RequireAuth = () => {
  const { session } = useSelector((state) => state.auth)

  const isRightRole = session?.role === 'USER' || session?.role === 'ADMIN'

  return !session?.token ? (
    <Navigate to="/login" />
  ) : isRightRole ? (
    <Outlet />
  ) : (
    'Without permision'
  )
}

export default RequireAuth
