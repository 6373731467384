import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import OrderItem from '../components/OrderItem'
import OrderSuccessModal from '../components/OrderSuccessModal'
import ChooseShiftModal from '../components/ChooseShiftModal'
import {
  useCreateUserOrderMutation,
  useGetMenuQuery,
} from '../features/apiSlice'

import checkOrder from '../assets/images/check-order.svg'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder } from '../features/orderSlice'

const Order = () => {
  const { id } = useParams()

  const { data: menu, isSuccess, isLoading } = useGetMenuQuery(id)
  const { session } = useSelector((state) => state.auth)

  const [createdAt, setCreatedAt] = useState('')

  const formatDate = (dateStr) => {
    const date = new Date(dateStr)

    // Define the format options for Serbian language
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
      locale: 'sr-Latn-RS',
    }

    // Format the date using the options
    const formattedDate = new Intl.DateTimeFormat('sr-Latn-RS', options).format(
      date
    )

    // Split the formatted date into day long, day numeric, and month long parts
    const [dayLong, dayNumeric, monthLong] = formattedDate.split(' ')

    return {
      dayLong:
        dayLong.split(',')[0][0].toUpperCase() + dayLong.split(',')[0].slice(1),
      dayNumeric,
      monthLong: monthLong[0].toUpperCase() + monthLong.slice(1),
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setCreatedAt(formatDate(menu.createdAt))
    }
  }, [isSuccess])

  const dispatch = useDispatch()
  const { chooseShift } = useSelector((state) => state.order)

  const [selectFood, setSelectFood] = useState(null)
  const [selectExtras, setSelectExtras] = useState(null)

  useEffect(() => {
    setSelectExtras(null)
  }, [selectFood])

  return (
    <>
      <div className="order">
        <div className="order__day">
          {isLoading && 'Loading...'}
          {createdAt && (
            <>
              <h3>{createdAt.dayLong}</h3>
              <p>
                {createdAt.dayNumeric} {createdAt.monthLong}
              </p>
            </>
          )}
        </div>
        <div className="order__items">
          {menu &&
            menu.FoodMenu.map((el, i) => (
              <OrderItem
                onClick={(e) => {
                  setSelectFood(el.food.id)
                }}
                key={i}
                selectExtras={selectExtras}
                setSelectExtras={setSelectExtras}
                active={
                  selectFood === el.food.id
                    ? true
                    : selectFood === null
                    ? null
                    : false
                }
                data={el}
              />
            ))}
        </div>
      </div>
      <div className="order__footer">
        <button
          onClick={() => {
            dispatch(
              createOrder({
                foodId: selectFood,
                menuId: id,
              })
            )
          }}
          className="order-btn"
          disabled={selectFood === null}
        >
          <img src={checkOrder} alt="order" />
          Potvrdi narudžbu
        </button>
      </div>
      {chooseShift && <ChooseShiftModal />}
    </>
  )
}

export default Order
