import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: '',
  shift: '',
  locationsId: '',
  foodId: '',
  menuId: '',
  orderId: '',
  chooseShift: false,
}
export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    reset: () => initialState,
    createOrder: (state, action) => {
      state.type = 'create'
      state.foodId = action.payload.foodId
      state.chooseShift = true
      state.menuId = action.payload.menuId
    },
    updateOrder: (state, action) => {
      state[action.payload.key] = action.payload.data
    },
    toggleChooseShift: (state) => {
      state.chooseShift = !state.chooseShift
    },
    editOrder: (state, action) => {
      state.type = 'start'
      state.shift = action.payload.shift
      state.foodId = action.payload.food.id
      state.orderId = action.payload.id
      state.menuId = action.payload.menuId
      state.locationsId = action.payload.location.id
    },

    editShift: (state) => {
      state.type = 'edit'
      state.chooseShift = true
    },
    changeType: (state, action) => {
      state.type = action.payload
    },
    resetOrder: () => initialState,
  },
})

export const {
  updateOrder,
  toggleChooseShift,
  createOrder,
  resetOrder,
  editOrder,
  editShift,
  changeType,
  reset,
} = orderSlice.actions
export default orderSlice.reducer
