import React, { useEffect, useState } from 'react'
import ButtonMain from './ButtonMain'

import { groupData } from '../assets/data'
import CustomSelectBox from './CustomSelectBox'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeType,
  toggleChooseShift,
  updateOrder,
} from '../features/orderSlice'
import {
  useCreateUserOrderMutation,
  useEditOrderMutation,
} from '../features/apiSlice'
import OrderSuccess from './OrderSuccessModal'
import Loading from './Loading/Loading'

const ChooseShiftModal = () => {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.auth.session)
  const { type, ...orderData } = useSelector((state) => state.order)

  const [modalData, setModalData] = useState()

  const [createOrder, { isLoading, isSuccess }] = useCreateUserOrderMutation()
  const [editOrder, { isLoading: editIsLoading, isSuccess: editIsSuccess }] =
    useEditOrderMutation()

  const locationData = useSelector((state) => state.userOrderDetails.locations)
  const shiftDataStore = useSelector((state) => state.userOrderDetails.shifts)

  const shiftData = shiftDataStore.map((el) => {
    if (el === 'firstShift') {
      return {
        name: 'Prva smjena',
        value: 'firstShift',
      }
    }
    if (el === 'secondShift') {
      return {
        name: 'Druga smjena',
        value: 'secondShift',
      }
    }
    if (el === 'thirdShift') {
      return {
        name: 'Treca smjena',
        value: 'thirdShift',
      }
    }
  })

  console.log(shiftDataStore)

  const onCreateOrder = () => {
    createOrder({
      menuId: orderData.menuId,
      foodId: orderData.foodId,
      group: orderData.group,
      shift: orderData.shift,
      locationsId: orderData.locationsId,
    })
  }

  const onEditOrder = () => {
    editOrder({
      orderId: orderData.orderId,
      foodId: orderData.foodId,
      shift: orderData.shift,
      locationsId: orderData.locationsId,
    })
    setModalData()
  }

  useEffect(() => {
    if (type === 'create') {
      const updateShiftData = {
        shift: shiftData.filter((el) => el.value === userData.shift)[0],
        group: groupData[0],
        locationsId: locationData.filter(
          (el) => el.value === userData.location
        )[0],
      }

      console.log(updateShiftData)
      setModalData(updateShiftData)
      Object.keys(updateShiftData).forEach((el) => {
        dispatch(
          updateOrder({
            key: el,
            data: updateShiftData[el].value,
          })
        )
      })
    }

    if (type === 'edit') {
      const updateShiftData = {
        shift: shiftData.filter((el) => el.value === orderData.shift)[0],
        group: groupData[0],
        locationsId: locationData.filter(
          (el) => el.value === orderData.locationsId
        )[0],
      }

      setModalData(updateShiftData)
    }
  }, [])

  const [successModal, setSuccessModal] = useState(false)

  useEffect(() => {
    if (isSuccess || editIsSuccess) {
      setSuccessModal(true)
    }
  }, [isSuccess, editIsSuccess])

  return (
    <>
      {isLoading || editIsLoading ? <Loading /> : null}
      {successModal && <OrderSuccess />}
      {modalData && !isSuccess && (
        <>
          <div
            onClick={() => {
              if (type === 'edit') dispatch(changeType('start'))
              dispatch(toggleChooseShift())
            }}
            className="modal-overlay"
          ></div>
          <div className="shift-modal">
            {shiftData.length === 1 && locationData.length === 1 ? (
              <p className="shift-modal__title-confirm">Da li ste sigurni?</p>
            ) : (
              <p className="shift-modal__title">Izaberite svoju smjenu.</p>
            )}

            {shiftData.length > 1 && (
              <div>
                <CustomSelectBox
                  defaultValue={modalData?.shift}
                  options={shiftData}
                  onSelect={(e) => {
                    dispatch(
                      updateOrder({
                        key: 'shift',
                        data: e.value,
                      })
                    )
                  }}
                />
              </div>
            )}

            <div>
              {locationData.length > 1 && (
                <CustomSelectBox
                  defaultValue={modalData?.locationsId}
                  options={locationData}
                  onSelect={(e) => {
                    dispatch(
                      updateOrder({
                        key: 'locationsId',
                        data: e.value,
                      })
                    )
                  }}
                />
              )}
              <div className="modal-btns">
                <ButtonMain
                  onClick={() =>
                    type === 'create' ? onCreateOrder() : onEditOrder()
                  }
                  background="orange"
                  text="Potvrdi"
                />
                <ButtonMain
                  onClick={() => {
                    if (type === 'edit') dispatch(changeType('start'))
                    dispatch(toggleChooseShift())
                  }}
                  background="grey-modal"
                  text="Odustani"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ChooseShiftModal
