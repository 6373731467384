const Footer = () => {
  return (
    <div className="footer">
      <div>
        powered by <img src="./img/formaplus.svg" />
      </div>
      <div>Copyright {new Date().getFullYear()} - All Rights Reserved</div>
    </div>
  )
}

export default Footer
