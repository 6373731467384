import React from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonMain from './ButtonMain'
import { useSelector } from 'react-redux'

const Day = ({ date, id, background, text, onClick, foodName, shift }) => {
  const navigate = useNavigate()
  const shiftDataStore = useSelector((state) => state.userOrderDetails.shifts)

  return (
    <div className="day">
      <div className="day__header">
        <h4>{date.dayLong.split(',')[0]}</h4>
        <p>
          {date.dayNumeric}{' '}
          {date.monthLong[0].toUpperCase() + date.monthLong.slice(1)}
        </p>
      </div>
      <div className="day__main">
        <p>Obrok:</p>
        <h4>{foodName ? foodName : 'Nije odabrano'}</h4>
        {foodName && shiftDataStore.length > 1 && (
          <>
            <p>Smjena:</p>
            <h4 style={{ marginBottom: '8px' }}>{shift}</h4>
          </>
        )}

        <ButtonMain onClick={onClick} background={background} text={text} />
      </div>
    </div>
  )
}

export default Day
