import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/api'

const initialState = {
  locations: [],
  shifts: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Login user
export const getOrderDetails = createAsyncThunk(
  'order/details',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.session.token

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }

    try {
      const { data } = await axios.get(API_URL + '/user/order-details', config)

      return data.body
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const userOrderDetailsSlice = createSlice({
  name: 'userOrderDetails',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.locations = action.payload.locations
        state.shifts = action.payload.shifts
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.isAuth = true
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const {} = userOrderDetailsSlice.actions
export default userOrderDetailsSlice.reducer
