import React from 'react'
import { useGetUserMenusQuery } from '../features/apiSlice'
import Day from './Day'
import DayLoading from './Loading/DayLoading'

import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

const Menu = () => {
  const navigate = useNavigate()
  const { data: menus, isLoading, isSuccess } = useGetUserMenusQuery()

  const formatDate = (dateStr) => {
    const date = new Date(dateStr)

    // Define the format options for Serbian language
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
      locale: 'sr-Latn-RS',
    }

    // Format the date using the options
    const formattedDate = new Intl.DateTimeFormat('sr-Latn-RS', options).format(
      date
    )

    // Split the formatted date into day long, day numeric, and month long parts
    const [dayLong, dayNumeric, monthLong] = formattedDate.split(' ')

    return { dayLong, dayNumeric, monthLong }
  }

  return (
    <motion.div className="menu">
      <h3>Izaberi dan za koji želiš da naručiš:</h3>

      <div className="days-container">
        <div className="days">
          {isLoading &&
            Array.from({ length: 6 }).map((el, i) => <DayLoading key={i} />)}

          {menus &&
            menus.map((menu, i) => (
              <Day
                key={i}
                date={formatDate(menu.createdAt)}
                id={menu.id}
                background="orange"
                text="Odaberi"
                onClick={() => navigate('/order/create/' + menu.id)}
              />
            ))}
        </div>
      </div>
    </motion.div>
  )
}

export default Menu
