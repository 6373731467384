import { useState } from 'react'

import ArrowDown from '../assets/images/circle_arrow_down.svg'

function CustomSelectBox({ defaultValue, options, onSelect }) {
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const [isActive, setIsActive] = useState(false)

  function handleSelect(option) {
    setSelectedOption(option)
    setIsActive(false)
    onSelect(option)
  }

  return (
    <div className="shift-box">
      <div className={`shift-box__info`} onClick={() => setIsActive(!isActive)}>
        {selectedOption.name}

        <img src={ArrowDown} />
      </div>
      {isActive && (
        <ul>
          {options.map((option, i) => (
            <li
              key={i}
              className={`option ${
                option.value === selectedOption.value ? 'active' : ''
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default CustomSelectBox
