import React from 'react'

const OrderItem = ({
  active,
  data,
  onClick,
  selectExtras,
  setSelectExtras,
}) => {
  const classGuard =
    active === null ? 'item' : active === true ? 'item active' : 'item disabled'

  return (
    <div onClick={onClick} className={classGuard}>
      <div className="item__main">
        <div className="info">
          <h4>{data.food.name}</h4>
          {data.extras.length > 0 ? (
            <p>Dodaci</p>
          ) : (
            <p>Dodaci ne dolaze uz ovo jelo.</p>
          )}
        </div>
        <div className="check active"></div>
      </div>
      {data.extras.length > 0 ? (
        <div className="item__exstras">
          <div>
            {' '}
            +{' '}
            {data.extras.map((ex, i) => {
              const comma = i !== data.extras.length - 1 ? ', ' : ''
              return ex.name + comma
            })}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default OrderItem
