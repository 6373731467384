import React from 'react'
import ButtonMain from './ButtonMain'

import tickImage from '../assets/images/tick.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetOrder } from '../features/orderSlice'

const OrderSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="success-modal">
        <img src={tickImage} alt="Success order" />
        <p className="success-modal__title">Uspješna narudžba</p>
        <p className="success-modal__description">
          U meniju za izmjene možete promijeniti narudžbu.
        </p>
        <ButtonMain
          onClick={() => {
            dispatch(resetOrder())
            navigate('/')
          }}
          background="orange"
          text="Nastavi"
        />
      </div>
    </>
  )
}

export default OrderSuccess
