import React from 'react'
import Avatar from 'react-avatar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Back from '../../assets/images/back.svg'
import Hamburger from '../../assets/images/hamburger.svg'
import Hero from '../../assets/images/Hero.svg'

import { modalToggler } from '../../features/layoutSlice'
import { reset } from '../../features/orderSlice'

const Navbar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { name, username } = useSelector((state) => state.auth.session)

  return (
    <div className="navbar">
      <div className="menu">
        {location.pathname !== '/' && (
          <button
            onClick={() => {
              if (location.pathname === '/order/edit') {
                dispatch(reset())
              }
              navigate(-1)
            }}
            className="back"
          >
            <img src={Back} alt="back" />
          </button>
        )}

        <h2>Naruči hranu</h2>

        <Avatar
          onClick={() => dispatch(modalToggler(true))}
          color="#f2f3f7"
          fgColor="#787878"
          round="100%"
          size="26px"
          className="hamburger"
          textSizeRatio={1.75}
          name={name.trim() !== '' ? name : username.split('.')[1]}
        />
      </div>
      <div className="hero">
        <h2>
          Planiraj svoj obrok <span>za sledeću sedmicu!</span>
        </h2>
        <img src={Hero} alt="Hero" />
      </div>
    </div>
  )
}

export default Navbar
