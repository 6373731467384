import React, { useEffect } from 'react'
import Navbar from '../components/Layout/Navbar'
import { Outlet } from 'react-router-dom'
import CloseModal from '../components/CloseModal'
import { useDispatch, useSelector } from 'react-redux'
import SignOutModal from '../components/SignOutModal'
import { getOrderDetails } from '../features/userOrderDetails'

const Layout = () => {
  const { closeModal, signOutModal } = useSelector((state) => state.layout)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrderDetails())
  }, [])

  return (
    <>
      <Navbar />
      {signOutModal && <SignOutModal />}
      {closeModal && <CloseModal />}
      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default Layout
