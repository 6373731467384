import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Layout/Footer'
import { encyptLogin, login } from '../features/authSlice'

import Logo from '../assets/images/logo.svg'
import BigLogo from '../assets/images/big_transparent_logo.svg'
import UserLogin from '../assets/images/login_user.svg'
import Loading from '../components/Loading/Loading'

import * as yup from 'yup'

const Login = () => {
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isAuth, isError, isLoading } = useSelector((state) => state.auth)

  const validator = async (uncrypt) => {
    const schema = yup.object().shape({
      group: yup.string().required(),
      location: yup.number().required(),
      name: yup.string(),
      role: yup.string().required(),
      shift: yup.string().required(),
      token: yup.string().required(),
      username: yup.string().required(),
    })

    try {
      const validSchema = await schema.validate(uncrypt)

      console.log(validSchema)

      dispatch(encyptLogin(uncrypt))
    } catch (error) {
      console.log('Not validate')
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const ss = urlParams.get('ss')
    if (ss) {
      try {
        const parsed = JSON.parse(
          decodeURIComponent(
            decodeURIComponent(
              Array.prototype.map
                .call(
                  atob(ss),
                  (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                )
                .join('')
            )
          )
        )

        validator(parsed)
      } catch (error) {
        console.log('Not valid JSON')
      }
    }
  }, [])

  const onSubmit = (data) => {
    dispatch(login(data))
  }

  useEffect(() => {
    if (isAuth) navigate('/')
  }, [isAuth])

  return (
    <>
      {isLoading && <Loading />}
      <div className="container">
        <div className="login">
          <h1 className="login-title">Prijava</h1>
          <img className="login-logo" src={Logo} />
          <div className="login-form">
            <img className="login-form-biglogo" src={BigLogo} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>Unesite korisničko ime:</label>
              <input
                {...register('username', { required: true })}
                placeholder="Username"
                className={isError ? 'error' : ''}
              />
              <label>Unesite lozinku:</label>
              <input
                {...register('password', { required: true })}
                placeholder="Lozinka"
                type="password"
                className={isError ? 'error' : ''}
              />
              <div className="handingForm">
                {isError && (
                  <span id="errorMsg">
                    Neispravno korisničko ime ili lozinka. Pokušajte ponovo.
                  </span>
                )}
                <button type="submit" className="submitBtn">
                  <img src={UserLogin} />
                  Prijavi se
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Login
