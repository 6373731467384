import React from 'react'
import ButtonMain from './ButtonMain'

const Selector = ({ step, setStep }) => {
  return (
    <div className="selector">
      <div className="selector__box">
        <ButtonMain
          onClick={() => setStep('archive')}
          background={step === 'archive' ? 'blue' : 'transparent'}
          text="Izmjeni"
        />
        <ButtonMain
          onClick={() => setStep('menu')}
          background={step === 'menu' ? 'orange' : 'transparent'}
          text="Naruči"
        />
      </div>
    </div>
  )
}

export default Selector
