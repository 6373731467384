import React, { useState } from 'react'
import Menu from '../components/Menu'
import Archive from '../components/Archive'
import Selector from '../components/Selector'

import ChooseShiftModal from '../components/ChooseShiftModal'
import OrderSuccess from '../components/OrderSuccessModal'
import { useSelector } from 'react-redux'

const Home = () => {
  const [step, setStep] = useState('menu')

  const { chooseShift } = useSelector((state) => state.order)

  return (
    <>
      {step === 'archive' && <Archive />}
      {step === 'menu' && <Menu />}
      <Selector step={step} setStep={setStep} />
    </>
  )
}

export default Home
