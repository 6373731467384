import React from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../features/authSlice'
import { signOutToggler, reset } from '../features/layoutSlice'
import ButtonMain from './ButtonMain'

const SignOutModal = () => {
  const dispatch = useDispatch()

  const handleClick = (e) => {
    if (e.target.classList.value === 'overlay') dispatch(signOutToggler(false))
  }

  const signOutHandler = () => {
    dispatch(logout())
  }

  return (
    <div className="overlay" onClick={handleClick}>
      <div className="modal signOutModal">
        <h3>Odjava</h3>
        <h5>Da li ste sigurni da želite da se odjavite?</h5>
        <button
          onClick={() => {
            dispatch(reset())
            signOutHandler()
          }}
          className="btn-sign-out"
        >
          Odjavi se
        </button>
        <button
          className="btn-sign-out back"
          onClick={() => dispatch(signOutToggler(false))}
        >
          Odustani
        </button>
      </div>
    </div>
  )
}

export default SignOutModal
