import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL + '/api'

export const apiSlice = createApi({
  reducerPath: 'api', // optional
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.session.token
      if (token) {
        headers.set('Content-Type', 'application/json')
        headers.set('authorization', `Bearer ${token}`)
        return headers
      }
    },
  }),
  tagTypes: ['getUserMenus', 'getUserOrders', 'getUserOrdersHistory'],
  endpoints: (builder) => ({
    getUserMenus: builder.query({
      query: () => ({
        url: `/user-menu/new`,
        method: 'GET',
      }),
      providesTags: ['getUserMenus'],
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    getMenu: builder.query({
      query: (id) => ({
        url: `/menu/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    getUserOrders: builder.query({
      query: () => ({
        url: `/order/user/locked`,
        method: 'GET',
      }),
      providesTags: ['getUserOrders'],
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    getUserOrdersHistory: builder.query({
      query: () => ({
        url: `/order/user/finished`,
        method: 'GET',
      }),
      providesTags: ['getUserOrdersHistory'],
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    createUserOrder: builder.mutation({
      query: (body) => ({
        url: `/order/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'getUserMenus',
        'getUserOrders',
        'getUserOrdersHistory',
      ],
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    editOrder: builder.mutation({
      query: (body) => ({
        url: `/order/update`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'getUserMenus',
        'getUserOrders',
        'getUserOrdersHistory',
      ],
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        console.log(response)
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/order/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'getUserMenus',
        'getUserOrders',
        'getUserOrdersHistory',
      ],
      transformResponse: (response) => {
        return response.body
      },
      transformErrorResponse: (response) => {
        console.log(response)
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
  }),
})

export const {
  useGetUserMenusQuery,
  useGetUserOrdersQuery,
  useGetUserOrdersHistoryQuery,
  useGetMenuQuery,
  useCreateUserOrderMutation,
  useEditOrderMutation,
  useDeleteOrderMutation,
} = apiSlice
