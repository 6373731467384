import React, { useEffect, useState } from 'react'
import {
  useGetUserOrdersQuery,
  useGetUserOrdersHistoryQuery,
} from '../features/apiSlice'
import Day from './Day'
import DayLoading from './Loading/DayLoading'

import { motion } from 'framer-motion'
import EditMeal from './EditMeal'
import ChooseShiftModal from './ChooseShiftModal'
import { useDispatch, useSelector } from 'react-redux'
import { editOrder } from '../features/orderSlice'
import { useLocation } from 'react-router-dom'

const Archive = () => {
  const location = useLocation()

  const { data: orders, isLoading, isSuccess } = useGetUserOrdersQuery()

  const {
    data: ordersHistory,
    isLoading: isLoadingOrdersHistory,
    isSuccess: isSuccessOrdersHisotory,
  } = useGetUserOrdersHistoryQuery()

  const formatDate = (dateStr) => {
    const date = new Date(dateStr)

    // Define the format options for Serbian language
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
      locale: 'sr-Latn-RS',
    }

    const formattedDate = new Intl.DateTimeFormat('sr-Latn-RS', options).format(
      date
    )

    const [dayLong, dayNumeric, monthLong] = formattedDate.split(' ')

    return { dayLong, dayNumeric, monthLong }
  }

  const [openEditOrder, setOpenEditOrder] = useState(false)

  const { chooseShift, type } = useSelector((state) => state.order)

  const dispatch = useDispatch()

  return (
    <>
      <div className="menu">
        <div className="days-container archive">
          <div>
            <h3>Izaberi dan za koji želiš da izmjeniš:</h3>
            <div className="days">
              {isLoading &&
                Array.from({ length: 6 }).map((el, i) => (
                  <DayLoading key={i} />
                ))}

              {orders &&
                orders.map((menu, i) => (
                  <Day
                    key={i}
                    date={formatDate(menu.createdAt)}
                    id={menu.id}
                    background="blue"
                    text="Izmjeni"
                    menu={menu}
                    shift={
                      menu.shift === 'firstShift'
                        ? 'Prva smjena'
                        : 'Druga smjena'
                    }
                    foodName={menu.food.name}
                    onClick={() => {
                      dispatch(editOrder(menu))
                    }}
                  />
                ))}
            </div>
          </div>

          <div>
            <h3>Istekle narudžbe:</h3>
            <div className="days">
              {isLoading &&
                Array.from({ length: 6 }).map((el, i) => (
                  <DayLoading key={i} />
                ))}

              {ordersHistory &&
                ordersHistory.map((menu, i) => (
                  <Day
                    key={i}
                    date={formatDate(menu.createdAt)}
                    id={menu.id}
                    background="gray"
                    text="Isteklo"
                    menu={menu}
                    shift={
                      menu.shift === 'firstShift'
                        ? 'Prva smjena'
                        : 'Druga smjena'
                    }
                    foodName={menu.food.name}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      {type === 'start' && <EditMeal />}

      {chooseShift && <ChooseShiftModal />}
    </>
  )
}

export default Archive
