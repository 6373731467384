export const groupData = [
  {
    value: 'firstGroup',
    name: 'Prva grupa',
  },
  {
    value: 'secondGroup',
    name: 'Druga grupa',
  },
  {
    value: 'thirdGrup',
    name: 'Treca grupa',
  },
]
export const shiftData = [
  {
    value: 'firstShift',
    name: 'Prva smjena',
  },
  {
    value: 'secondShift',
    name: 'Druga smjena',
  },
]
export const locationData = [
  {
    value: 1,
    name: 'Elas',
  },
  {
    value: 2,
    name: 'Ramici',
  },
]
